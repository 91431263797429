import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-162d47f8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "btn-div"
};
const _hoisted_2 = ["disabled"];
import { ref, toRefs } from "vue";
/** 工具 */
import { uploadObs } from "hxhy-obs";
import bus from "@/util/bus.js";

/** ui组件库 */
import { MessagePlugin } from 'tdesign-vue-next';
/** api */
import { getCoverByUrl } from '@/api/personalPhotoes.js';
const ACCEPT_TYPE = ".jpg, .png, .svg, .jpeg, .bmp, .avi, .wmv, .mpg, .mpeg, .mov, .rm, .ram, .swf, .flv, .mp4, .3gp, .webp, .gif";

/** 逻辑处理 */
// 上传进度组件中重新上传文件，单独调接口

export default {
  __name: 'uploadImg',
  props: {
    imgArr: {
      // 上传数据
      type: String,
      default: ''
    },
    sizeLimit: {
      // 大小限制
      type: Number,
      default: null
    },
    isClass: {
      // 是否班级相册 0 个人相册 1 班级相册
      type: Number,
      default: null
    },
    userType: {
      type: String,
      default: "student"
    }
  },
  emits: 'uploadSuccess',
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const {
      sizeLimit
    } = toRefs(props);
    const IMG_VIDEO_ARR = ["jpg", "png", "svg", "jpeg", "bmp", "avi", "wmv", "mpg", "mpeg", "mov", "rm", "ram", "swf", "flv", "mp4", "3gp", "webp", "gif"];
    bus.on("reUploadFile", index => {
      handleUpload(fileStatus.value[index]);
    });
    bus.on("deleteUploadFile", file => {
      changeImgArrMtd(file);
    });

    // 上传数据
    const uploadImgArr = ref([]);
    // 文件上传状态
    const fileStatus = ref([]);
    /**
     * 上传文件
     */
    const getImg = async e => {
      var _e$target;
      const files = e === null || e === void 0 ? void 0 : (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.files;
      // console.log('files', files)
      fileStatus.value = [];
      // 拿到要上传的文件，传递给上传进度组件
      for (let index = 0; index < files.length; index++) {
        const fileName = files[index].name.substring(files[index].name.lastIndexOf(".") + 1);
        const bool = IMG_VIDEO_ARR.indexOf(fileName) > -1;
        if (bool) {
          // 过滤出符合格式文件
          let statu = "等待上传";
          const showSize = returnFileSize(files[index].size);
          let btnTxt = "取消上传";
          if (files[index].size > 1024 * 1024 * 200) {
            statu = "传输失败";
            btnTxt = "文件需≤200M";
          }
          fileStatus.value.push({
            url: "",
            statu,
            showSize,
            btnTxt,
            file: files[index]
          });
        }
      }
      // console.log("86----fileStatus.value", fileStatus.value);
      bus.emit("beginUploadingPhotoesAndVideos", fileStatus.value);
      // 将每个文件进行上传
      uploadImgArr.value = [];
      if (fileStatus.value.length === 1 && fileStatus.value[0].statu === '传输失败') {
        bus.emit("afterUploadStatuChange", fileStatus.value);
      } else {
        fileStatus.value.forEach((i, index) => {
          if (i.statu !== "传输失败") handleUpload(i);
        });
      }

      // 最后清空文件列表
      document.getElementById("file").value = ''; // 清空上传数据
    };

    /** 处理逻辑相关方法 */
    /**
     * 图片大小单位换算
     */
    const returnFileSize = number => {
      if (number < 1024) {
        //小于1024的，直接以字节为单位
        return number + 'bytes';
      } else if (number > 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + 'KB';
      } else if (number > 1048576) {
        return (number / 1048576).toFixed(1) + 'MB';
      }
    };

    /**
     * 上传接口调用
     */
    const handleUpload = i => {
      const accessInfo = JSON.parse(localStorage.getItem("accessInfo"));
      const institutionInfo = JSON.parse(localStorage.getItem("userInfo"));
      const bucketSuffix = ["dev", "test", "pre", "prod"].find(v => {
        return location.host.includes(v);
      }) || "prod";
      const bucketName = `hxhy-photo-${bucketSuffix}`;
      const opt = {
        bucketName,
        file: i.file,
        fileType: i.file.type.split("/").includes("image") ? 2 : 3,
        userId: accessInfo.content.user_id,
        userName: accessInfo.content.username,
        institutionId: institutionInfo.content.institutionId,
        institutionName: institutionInfo.content.institutionName,
        appCode: "classPhoto",
        appName: "班级相册",
        pageName: "classPhoto",
        btnName: "上传相册",
        bucketMark: "photo"
      };
      uploadObs(opt).then(async res => {
        i.statu = "传输完成";
        i.btnTxt = "删除";
        i.url = res;
        const resourceType = i.file.type.split("/").includes("image") ? 0 : 1; // 照片类型 0:图片 1:视频
        let coverRes = '';
        if (resourceType === 1) {
          coverRes = await getCoverByUrl(res);
        }
        uploadImgArr.value.push({
          type: resourceType,
          name: i.file.name.slice(0, 16),
          // 图片或视频名称
          url: res,
          // 照片或视频地址
          cover: resourceType === 1 ? coverRes.data.data : '' // 视频封面
        });
        emit("update:imgArr", uploadImgArr.value);
        bus.emit("afterUploadStatuChange", fileStatus.value);
      }).catch(err => {
        i.statu = "传输失败";
        i.btnTxt = "重新上传";
        bus.emit("afterUploadStatuChange", fileStatus.value);
      });
    };

    /**
     * 更改上传入参
     */
    const changeImgArrMtd = file => {
      uploadImgArr.value = uploadImgArr.value.filter(i => i.name !== file.file.name.slice(0, 16));
      emit("update:imgArr", uploadImgArr.value);
    };
    return (_ctx, _cache) => {
      const _component_t_button = _resolveComponent("t-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_t_button, {
        disabled: __props.isClass === 1 && __props.userType === 'student',
        theme: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("上传照片/视频")]),
        _: 1
      }, 8, ["disabled"]), _createElementVNode("input", {
        id: "file",
        type: "file",
        multiple: "",
        accept: ACCEPT_TYPE,
        disabled: __props.isClass === 1 && __props.userType === 'student',
        class: _normalizeClass(["input-img", {
          'is-disabled': __props.isClass === 1 && __props.userType === 'student'
        }]),
        onChange: getImg
      }, null, 42, _hoisted_2)]);
    };
  }
};