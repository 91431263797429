import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5180258a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "dialog",
  class: "visible"
};
import { computed } from "vue";
export default {
  __name: 'modalCommon',
  props: {
    modalVisible: Boolean,
    titleName: {
      // 弹窗标题名字
      type: String,
      default: "保存"
    },
    modalWidth: {
      // 弹窗宽度
      type: [Number, String],
      default: "25%"
    },
    footer: {
      //底部操作栏，默认会有“确认”和“取消”两个按钮。值为 true 显示默认操作按钮，值为 false 不显示任何内容
      type: Boolean,
      default: true
    },
    hiddenCancelBtn: {
      type: Object,
      default() {
        return {};
      }
    },
    saveBtn: {
      // 保存按钮文案
      type: String,
      default: "保存"
    },
    cancelBtn: {
      // 取消按钮文案
      type: String,
      default: "取消"
    },
    closeOnOverlayClick: {
      // 点击蒙层时是否触发关闭事件
      type: Boolean,
      default: true
    }
  },
  emits: ["handleSave", "handleCancel"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const visible = computed(() => {
      // 弹窗显隐
      return props.modalVisible;
    });
    const handleOk = () => {
      emit("handleSave");
    };
    const handleCancel = () => {
      // 取消
      emit("handleCancel");
    };
    return (_ctx, _cache) => {
      const _component_t_dialog = _resolveComponent("t-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_t_dialog, {
        visible: visible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => visible.value = $event),
        width: __props.modalWidth,
        header: __props.titleName,
        closeOnOverlayClick: __props.closeOnOverlayClick,
        "confirm-btn": __props.saveBtn,
        "cancel-btn": __props.cancelBtn,
        footer: __props.footer,
        "cancel-button-props": __props.hiddenCancelBtn,
        onClose: handleCancel,
        onConfirm: handleOk,
        onCancel: handleCancel
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["visible", "width", "header", "closeOnOverlayClick", "confirm-btn", "cancel-btn", "footer", "cancel-button-props"])], 512);
    };
  }
};