import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
import modalCommon from "@/components/modalCommon.vue";
/** 组合式api */
import { ref } from "vue";
export default {
  __name: 'deleteDialog',
  props: {
    titleName: {
      type: String,
      default: "删除"
    }
  },
  emits: ["changeVisible", "handleUpload"],
  setup(__props, {
    emit: __emit
  }) {
    /** 组件 */
    const props = __props;
    const emit = __emit;

    /** 事件相关 */
    /**
     * 确定
     */
    const handleSave = () => {
      emit("handleUpload");
    };
    /**
     * 关闭弹窗
     */
    const handleCancel = () => {
      emit("changeVisible");
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(modalCommon, {
        modalWidth: "25%",
        titleName: __props.titleName,
        saveBtn: "确认",
        onHandleSave: handleSave,
        onHandleCancel: handleCancel
      }, {
        default: _withCtx(() => [__props.titleName === '删除' ? (_openBlock(), _createElementBlock("div", _hoisted_1, "是否删除该照片/视频？")) : (_openBlock(), _createElementBlock("div", _hoisted_2, "是否删除所有选中照片/视频？"))]),
        _: 1
      }, 8, ["titleName"]);
    };
  }
};