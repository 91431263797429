import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bba2625e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "allDays"
};
const _hoisted_2 = {
  class: "day-text"
};
const _hoisted_3 = ["id"];
const _hoisted_4 = {
  class: "photo-div"
};
const _hoisted_5 = ["src", "onClick"];
const _hoisted_6 = ["src", "onClick"];
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "like-center"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = ["src"];
const _hoisted_11 = {
  key: 4,
  class: "delete-div"
};
const _hoisted_12 = ["src", "onClick"];
const _hoisted_13 = {
  class: "title-box"
};
const _hoisted_14 = ["contenteditable", "onBlur"];
import bus from "@/util/bus.js";
/** ui组件库 */
import { MessagePlugin as message } from 'tdesign-vue-next';
/** 接口 */
import { admirePhoto } from "@/api/classPhotoes.js";
/** 组合式api */
import { ref, toRefs, watch } from "vue";
export default {
  __name: 'dayPhotoes',
  props: {
    checkedNum: {
      // 选中照片数量
      type: Number,
      default: 0
    },
    monthDay: {
      // 月日数组
      type: Array,
      default() {
        return [];
      }
    },
    showCheckBox: {
      // 勾选框显隐
      type: Boolean,
      default: false
    },
    isClass: {
      // 是否班级相册 0 个人相册 1 班级相册
      type: Number,
      default: null
    },
    userType: {
      type: String,
      default: "student"
    }
  },
  emits: ["update:checkedNum"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    /** 工具 */
    const props = __props;
    const {
      monthDay,
      userType
    } = toRefs(props);
    const editLabelRef = ref(null);
    const emit = __emit;
    let USER_INFO = JSON.parse(localStorage.getItem("accessInfo") || "{}");
    const userId = ref(null);
    userId.value = USER_INFO.content.user_id;

    // bus.on("afterChangeTimeLineMonthDay", (changeDayMonthDay) => {
    //   // initAllIdsMtd(changeDayMonthDay)
    // })

    /** 初始化相关方法 */
    const initAllIdsMtd = data => {
      allIds.value = data.map((i, index) => {
        const findItem = allIds.value.find(a => a.uploadTime === i.uploadTime);
        return {
          dateStr: i.dateStr,
          uploadTime: i.uploadTime,
          checkAll: findItem ? findItem.checkAll : false,
          // 全选
          indeterminate: findItem ? findItem.indeterminate : false,
          // 半选
          disabled: findItem ? findItem.disabled : false,
          ids: i.photoInfo.map((it, ix) => {
            const findId = findItem === null || findItem === void 0 ? void 0 : findItem.ids.find(b => b.id === it.id);
            return {
              id: it.id,
              isChecked: findId ? findId.isChecked : false,
              // 单选
              disabled: findId ? findId.disabled : userType.value === 'teacher' && it.uploadUserId !== USER_INFO.content.user_id,
              uploadUserId: it.uploadUserId
            };
          })
        };
      });
      allIds.value.forEach(i => {
        const findData = i.ids.find(it => it.disabled === false);
        i.disabled = findData ? false : true;
        const filtercheckedData = i.ids.filter(item => item.isChecked === true);
        i.checkAll = filtercheckedData.length === i.ids.length;
        i.indeterminate = filtercheckedData.length < i.ids.length && filtercheckedData.length > 0;
      });
    };
    watch(() => props.monthDay, nval => {
      // console.log("128-更新monthDay", nval);
      initAllIdsMtd(nval);
    }, {
      deep: true
    });

    /** 初始化相关 */
    // id按天分组集合_数据结构同MonthDay
    const allIds = ref([]);
    initAllIdsMtd(monthDay.value);

    /** 事件相关 */
    /**
     * 全选
     */
    const changeCheckAll = (bool, index) => {
      // allIds.value[index].ids.forEach((i, ix) => { i.id = monthDay.value[index].photoInfo[ix].id}) // monthDay加载后才拿到id,给对应allIds点击项下所有id赋值
      allIds.value[index].indeterminate = false;
      allIds.value[index].ids.forEach(i => {
        if (!i.disabled) i.isChecked = bool;
      });
      emit("update:checkedNum", getCheckedIds().length);
    };

    /**
     * 单个勾选_全选勾选判断
     */
    const changeSingleCheck = (bool, index, ix) => {
      // allIds.value[index].ids[ix].id = monthDay.value[index].photoInfo[ix].id // monthDay加载后才拿到id,给对应allIds点击项id赋值
      const filterCheckedLength = allIds.value[index].ids.filter(i => i.isChecked).length;
      const selectableLength = allIds.value[index].ids.filter(i => i.disabled === false).length;
      allIds.value[index].checkAll = filterCheckedLength === selectableLength;
      allIds.value[index].indeterminate = filterCheckedLength > 0 && filterCheckedLength < selectableLength;
      emit("update:checkedNum", getCheckedIds().length);
    };

    /**
     * 点赞、取消点赞
     */
    const changeLike = (id, msg, uploadTime) => {
      admirePhotoMtd(id, msg, uploadTime);
    };

    /**
     * 单个删除
     */
    const handleSingleDelete = (id, uploadTime) => {
      const data = {
        id,
        uploadTime
      };
      bus.emit("singleCheckedDeleteEvent", data);
    };

    /**
     * 单个编辑
     */
    const changeSingleLabel = (e, it, uploadTime) => {
      const name = e.target.innerHTML.replace(/<br>/g, '').slice(0, 16);
      const data = {
        id: it.id,
        name,
        remark: it.remark,
        uploadTime
      };
      if (it.name !== name) bus.emit("singleCheckedEditEvent", data);
    };

    /**
     * 查看
     */
    const viewPhoto = it => {
      bus.emit("viewPhotoOrVideoEvent", it);
    };

    /** 事件相关_方法 */
    /**
     * 过滤得到所有选中项id集合
     */
    const getCheckedIds = () => {
      var _allIds$value;
      const ids = [];
      (_allIds$value = allIds.value) === null || _allIds$value === void 0 ? void 0 : _allIds$value.forEach(i => {
        i.ids.forEach(it => {
          if (it.isChecked) ids.push(it.id);
        });
      });
      return ids;
    };

    /**
     * 编辑完成后清空勾选项
     */
    const emptyCheckedMtd = () => {
      allIds.value.forEach(i => {
        i.checkAll = false;
        i.indeterminate = false;
        i.ids.forEach(it => {
          it.isChecked = false;
        });
      });
      // allIds.value.forEach(i => {
      //   const findData = i.ids.find(it => it.isChecked === true)
      //   if (!findData) i.checkAll = false
      // })
    };

    /**
     * 点赞/取消点赞接口
     */
    const admirePhotoMtd = (id, msg, uploadTime) => {
      admirePhoto(id).then(res => {
        if (res.data.code === 0) {
          message.success(msg);
          bus.emit("afterChangeLikeRefreshList", uploadTime);
        }
      });
    };

    // 暴露方法
    __expose({
      getCheckedIds,
      emptyCheckedMtd
    });
    return (_ctx, _cache) => {
      const _component_t_checkbox = _resolveComponent("t-checkbox");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(monthDay), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "item-day"
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          id: item.jumpDate
        }, _toDisplayString(item.dateStr), 9, _hoisted_3)]), __props.showCheckBox ? (_openBlock(), _createBlock(_component_t_checkbox, {
          key: 0,
          modelValue: allIds.value[index].checkAll,
          "onUpdate:modelValue": $event => allIds.value[index].checkAll = $event,
          disabled: allIds.value[index].disabled,
          indeterminate: allIds.value[index].indeterminate,
          class: "all-check",
          onChange: $event => changeCheckAll($event, index)
        }, {
          default: _withCtx(() => [_createTextVNode(" 全选 ")]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "indeterminate", "onChange"])) : _createCommentVNode("", true), _createElementVNode("div", {
          class: _normalizeClass(["item-box", {
            'class-box': __props.isClass === 1
          }])
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.photoInfo, (it, ix) => {
          return _openBlock(), _createElementBlock("div", {
            key: ix,
            class: "item-div"
          }, [_createElementVNode("div", _hoisted_4, [it.type === 0 ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: it.url,
            alt: "",
            class: "img-sty",
            onClick: $event => viewPhoto(it)
          }, null, 8, _hoisted_5)) : _createCommentVNode("", true), it.type === 1 ? (_openBlock(), _createElementBlock("video", {
            key: 1,
            src: it.url,
            class: "img-sty",
            onClick: $event => viewPhoto(it)
          }, null, 8, _hoisted_6)) : _createCommentVNode("", true), __props.showCheckBox ? (_openBlock(), _createBlock(_component_t_checkbox, {
            key: 2,
            modelValue: allIds.value[index].ids[ix].isChecked,
            "onUpdate:modelValue": $event => allIds.value[index].ids[ix].isChecked = $event,
            disabled: allIds.value[index].ids[ix].disabled,
            class: "item-check",
            onChange: $event => changeSingleCheck($event, index, ix)
          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])) : _createCommentVNode("", true), __props.isClass && !__props.showCheckBox ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "like-div",
            onClick: $event => changeLike(it.id, it.admireFlag ? '取消点赞成功' : '点赞成功', item.uploadTime)
          }, [_createElementVNode("div", _hoisted_8, [!it.admireFlag ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require('@/assets/like.svg'),
            alt: "",
            class: "like-icon"
          }, null, 8, _hoisted_9)) : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: require('@/assets/like-active.svg'),
            alt: "",
            class: "like-icon"
          }, null, 8, _hoisted_10)), _createElementVNode("span", {
            class: _normalizeClass({
              'yellow-span': it.admireFlag === 1
            })
          }, _toDisplayString(it.admireNum > 1000 ? '1000+' : it.admireNum), 3)])], 8, _hoisted_7)) : _createCommentVNode("", true), !__props.showCheckBox && !(_unref(userType) === 'teacher' && allIds.value[index].ids[ix].uploadUserId !== userId.value || __props.isClass === 1 && _unref(userType) === 'student') ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("img", {
            src: require('@/assets/deleteIcon.svg'),
            class: "delete-icon",
            onClick: $event => handleSingleDelete(it.id, item.uploadTime)
          }, null, 8, _hoisted_12)])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", null, [_createElementVNode("div", {
            ref_for: true,
            ref_key: "editLabelRef",
            ref: editLabelRef,
            contenteditable: !__props.showCheckBox && !(_unref(userType) === 'teacher' && allIds.value[index].ids[ix].uploadUserId !== userId.value || __props.isClass === 1 && _unref(userType) === 'student'),
            class: _normalizeClass(["title-div", {
              'not-allowed-edit': !(!__props.showCheckBox && !(_unref(userType) === 'teacher' && allIds.value[index].ids[ix].uploadUserId !== userId.value || __props.isClass === 1 && _unref(userType) === 'student'))
            }]),
            onBlur: $event => changeSingleLabel($event, it, item.uploadTime)
          }, _toDisplayString(it.name), 43, _hoisted_14)])])]);
        }), 128))], 2)]);
      }), 128))]);
    };
  }
};