export const apiList = {
  personalPhotoes: {
    getList: '/photo/photo/getList', // 相册列表
    getCoverByUrl: '/resource/common/getCoverByUrl', // 获取图片封面
    createPhoto: '/photo/photo/create', // 上传图片或视频
    batchUpdatePhoto: '/photo/photo/batchUpdatePhoto', // 批量修改照片
    updatePhoto: '/photo/photo/updatePhoto', // 修改单个图片信息
    delPhoto: '/photo/photo/delPhoto', // 批量删除照片
    viewPhoto: '/photo/photo/viewPhoto/', // 查看照片
    getCurrentPosition: '/photo/photo/getCurrentPosition', // 获取当前图片所在位置
    findDetail: '/photo/photo/findDetail', // 相册详情图片或视频
    getPhotoCount: '/photo/photo/getPhotoCount', // 相册照片数量点赞数-查看数
  },
  classPhotoes: {
    deptList: '/admin/dept/deptList', // 获取部门列表
    updateSort: '/admin/dept/updateSort', // 修改部门排序
    admirePhoto: '/photo/photo/admirePhoto/', // 照片点赞
    getLeader: '/admin/dept/getLeader/', // 根据部门ID查询负责人
    getInfoById: '/admin/user/getById/' // 根据userId获取用户信息
  }
};
