import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import modalCommon from "@/components/modalCommon.vue";
import { ref } from "vue";
export default {
  __name: 'editDialog',
  emits: ["changeVisible", "handleUpload"],
  setup(__props, {
    emit: __emit
  }) {
    /** 组件 */
    const emit = __emit;

    /** 初始化相关 */
    const form = ref({
      name: "",
      // 名称
      remark: "" // 备注
    });

    /** 事件相关 */
    /**
     * 确定
     */
    const handleSave = () => {
      emit("handleUpload", form.value);
    };

    /**
     * 关闭弹窗
     */
    const handleCancel = () => {
      emit("changeVisible", form.value);
    };
    return (_ctx, _cache) => {
      const _component_t_input = _resolveComponent("t-input");
      const _component_t_form_item = _resolveComponent("t-form-item");
      const _component_t_textarea = _resolveComponent("t-textarea");
      const _component_t_form = _resolveComponent("t-form");
      return _openBlock(), _createBlock(modalCommon, {
        modalWidth: "25%",
        titleName: "编辑照片/视频",
        closeOnOverlayClick: false,
        cancelBtn: null,
        saveBtn: "确认",
        class: "edit-modal",
        onHandleSave: handleSave,
        onHandleCancel: handleCancel
      }, {
        default: _withCtx(() => [_createVNode(_component_t_form, {
          data: form.value,
          labelAlign: "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_t_form_item, {
            label: "名称",
            "label-width": "calc(2em)",
            colon: false
          }, {
            default: _withCtx(() => [_createVNode(_component_t_input, {
              modelValue: form.value.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.value.name = $event),
              placeholder: "请输入照片/视频名称",
              maxlength: 16
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_t_form_item, {
            label: "备注",
            "label-width": "calc(2em)",
            colon: false
          }, {
            default: _withCtx(() => [_createVNode(_component_t_textarea, {
              modelValue: form.value.remark,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.value.remark = $event),
              placeholder: "请输入备注",
              maxlength: 50
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      });
    };
  }
};