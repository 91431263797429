import request from "@/api/axios";
import { apiList as api } from "./apiList";

// 查询相册列表
export function deptList(data) {
  return request({
    url: api.classPhotoes.deptList,
    method: "post",
    data
  });
}

// 修改部门排序
export function updateSort(data) {
  return request({
    url: api.classPhotoes.updateSort,
    method: "post",
    data
  });
}

// 照片点赞
export function admirePhoto(id) {
  return request({
    url: api.classPhotoes.admirePhoto + id,
    method: "get"
  });
}

// 根据部门ID查询负责人
export function getLeader(id) {
  return request({
    url: api.classPhotoes.getLeader + id,
    method: "get"
  });
}

// 根据userId获取用户信息
export function getInfoById(id) {
  return request({
    url: api.classPhotoes.getInfoById + id,
    method: "get"
  });
}
