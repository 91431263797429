import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40686846"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "single-photo"
};
const _hoisted_2 = {
  class: "two"
};
import uploadImg from "@/views/commonComponents/uploadImg.vue";
import mainCom from "@/views/commonComponents/mainCom.vue";
import uploadProgress from "@/views/commonComponents/uploadProgress.vue";
import editDialog from "@/views/commonComponents/dialogs/editDialog.vue";
import deleteDialog from "@/views/commonComponents/dialogs/deleteDialog.vue";
import viewDialog from "@/views/commonComponents/dialogs/viewDialog.vue";
/** ui组件库 */
import { MessagePlugin as message } from 'tdesign-vue-next';
/** 工具 */
import bus from "@/util/bus.js";
/** 接口 */
import { getList, createPhoto, batchUpdatePhoto, updatePhoto, delPhoto, getPhotoCount } from "@/api/personalPhotoes.js";
/** 组合式api */
import { ref, toRefs, watch } from "vue";
export default {
  __name: 'index',
  props: {
    isClass: {
      // 是否班级相册 0 个人相册 1 班级相册
      type: Number,
      default: 0
    },
    deptInfo: {
      // 班级id和班级name
      type: Object,
      default() {
        return {};
      }
    },
    institutionId: {
      // 机构ID
      type: Number,
      default: 0
    },
    userType: {
      type: String,
      default: "student"
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    /** 组件 */
    const props = __props;
    const {
      isClass,
      deptInfo,
      institutionId
    } = toRefs(props);
    const mainComRef = ref(null);
    const viewDialogRef = ref(null);
    let USER_INFO = JSON.parse(localStorage.getItem("accessInfo") || "{}");
    const dateParam = ref("");
    bus.off("singleCheckedDeleteEvent");
    bus.on("singleCheckedDeleteEvent", data => {
      dateParam.value = data.uploadTime;
      idList.value = [data.id];
      deleteVisible.value = true;
    });
    bus.off("singleCheckedEditEvent");
    bus.on("singleCheckedEditEvent", data => {
      dateParam.value = data.uploadTime;
      const {
        uploadTime,
        ...data2
      } = data;
      singleEditPhotoMtd(data2, dateParam.value);
    });
    const viewInfo = ref({}); // 查看图片信息
    bus.off("viewPhotoOrVideoEvent");
    bus.on("viewPhotoOrVideoEvent", async info => {
      viewInfo.value = info;
      viewVisible.value = true;
      getPhotoCountMtd(1);
    });
    bus.off("afterChangeLikeRefreshList");
    bus.on("afterChangeLikeRefreshList", uploadTime => {
      refreshListMtd(uploadTime);
      getPhotoCountMtd(1);
    });

    // 监听窗口大小，根据每行照片显示数动态改变scrollTop值
    window.addEventListener('resize', function () {
      var _mainComRef$value;
      getLineNumMtd();
      const dateCheckBoxHeight = (_mainComRef$value = mainComRef.value) !== null && _mainComRef$value !== void 0 && _mainComRef$value.showCheckBox ? 64 : 38;
      monthDay.value.forEach((i, index) => {
        if (index > 0) {
          i.scrollTop = monthDay.value[index - 1].scrollTop + dateCheckBoxHeight + Math.ceil(monthDay.value[index - 1].cnt / lineNum.value) * 212;
        } else {
          i.scrollTop = 0;
        }
      });
    });

    /** 初始化相关_方法 */
    /** 
     * 获取当前年月日
     */
    const getCurYearMonthDayMtd = () => {
      const nowDate = new Date();
      return `${nowDate.getFullYear()}-${(nowDate.getMonth() + 1).toString().padStart(2, "0")}-${nowDate.getDate().toString().padStart(2, "0")}`;
    };

    /**
     * 获取相册列表入参_个人相册/班级相册
     * @param {flag} 是否是第一次加载(第一次加载会返回时间轴信息) 0:是， 1:否
     */
    const getSearchDataMtd = flag => {
      const data = {
        isClass: isClass.value,
        // 是否班级相册 0 个人相册 1 班级相册
        flag,
        // 是否是第一次加载
        uploadUserId: USER_INFO.content.id,
        dateArr: flag ? [getCurYearMonthDayMtd()] : null
      };
      if (isClass.value) {
        // 班级相册
        data.institutionId = USER_INFO.content.institutionId;
        data.deptId = deptInfo.value.id;
      }
      return data;
    };

    /**
     * 相册照片数量点赞数-查看数
     * @param {flag} 是否是第一次加载(第一次加载会返回时间轴信息) 0:是， 1:否
     */
    const getPhotoCountMtd = flag => {
      const data = getSearchDataMtd(flag);
      // 数量显示处理
      const dealNumShow = num => {
        let showNum = num;
        if (num > 100000) {
          showNum = "10W+";
        } else if (num === 100000) {
          showNum = "10W";
        } else if (num < 100000 && num >= 10000) {
          showNum = `${String(num).slice(0, 2)},${String(num).slice(2)}`;
        } else if (num < 10000 && num >= 1000) {
          showNum = `${String(num).slice(0, 1)},${String(num).slice(1)}`;
        }
        return showNum;
      };
      getPhotoCount(data).then(res => {
        photoNum.value = [{
          icon: "photo.svg",
          count: dealNumShow(res.data.data.photoCount)
        }, {
          icon: "gray-good.svg",
          count: dealNumShow(res.data.data.admireCount)
        }, {
          icon: "eyes.svg",
          count: dealNumShow(res.data.data.viewCount)
        }];
      });
    };

    /**
     * 根据窗口宽度，获取一行照片数量
     */
    const getLineNumMtd = () => {
      const boxWidth = document.documentElement.clientWidth;
      if (!isClass.value) {
        lineNum.value = boxWidth < 717 ? 1 : boxWidth >= 1837 ? 6 : Math.ceil((boxWidth - 716) / 280) + 1;
      } else {
        lineNum.value = boxWidth < 945 ? 1 : boxWidth >= 1833 ? 5 : Math.ceil((boxWidth - 944) / 296) + 1;
      }
    };

    /** 初始化相关 */
    // 是否隐藏上传照片/视频功能
    const isHideUploadBtn = ref(false);
    isHideUploadBtn.value = USER_INFO.content.memberType.includes('student') && !(USER_INFO.content.memberType.includes('manager') || USER_INFO.content.memberType.includes('teacher'));

    // 一行照片数量
    const lineNum = ref(null);
    getLineNumMtd();
    // 上传进度组件显隐
    const showProgress = ref(true);
    // 个人相册空状态
    const isEmpty = ref(true);
    // 上传数据双向绑定
    const imgArr = ref([]);
    const photoNum = ref([]); // 照片数、点赞数
    const yearMonth = ref([]); // 年月数组
    const monthDay = ref([]); // 月日数组

    /**
     * 获取相册列表
     */
    const cloneMonthDay = ref([]); // 用于批量删除后本地过滤掉已删除数据，保存已加载过数据信息
    const getPhotoList = (dateParam, idList) => {
      const data = getSearchDataMtd(0);
      if (dateParam) data.uploadTime = [dateParam];
      getList(data).then(res => {
        if (res.data.code === 0) {
          if (idList) {
            // 批量删除数据备份
            cloneMonthDay.value = JSON.parse(JSON.stringify(monthDay.value));
            cloneMonthDay.value.forEach(i => {
              const filterData = i.photoInfo.filter(it => idList.includes(it.id) === false);
              i.photoInfo = filterData;
              i.cnt = filterData.length;
            });
          }
          dealTimeLineMtd(res.data.data, idList);
        }
      });
      getPhotoCountMtd(0);
    };
    if (!isClass.value) getPhotoList();

    /** 监听 */
    watch(() => props.deptInfo, nval => {
      if (nval.id) getPhotoList(); // 获取班级相册列表
    }, {
      deep: true
    });

    /** 事件相关 */
    /**
     * 进度组件触发相关事件_开始上传
     */
    const beginUpload = () => {
      isEmpty.value = false;
      mainComRef.value.handleFinish();
    };
    const editFromUpload = ref(true); // 编辑弹窗触发方式：上传按钮/批量编辑按钮
    const idList = ref([]); // 勾选id集合
    const editVisible = ref(false); // 编辑弹窗显隐
    /**
     * 编辑弹窗显隐切换
     */
    const changeEditVisible = form => {
      editVisible.value = false;
      if (editFromUpload.value) handleCreatePhoto(form); // 上传
    };
    /**
     * 批量编辑
     * @param {ids} 选中项id集合
     */
    const handleBatchEdit = ids => {
      if (!ids.length) {
        message.warning("请先选择要操作的数据");
        return;
      }
      idList.value = ids;
      editFromUpload.value = false;
      editVisible.value = true;
    };
    /**
     * 编辑弹窗确认事件
     * @param {form} 名称和备注
     */
    const handleUpload = form => {
      editVisible.value = false;
      if (editFromUpload.value) {
        // 上传
        isEmpty.value = true;
        handleCreatePhoto(form);
      } else {
        // 批量编辑
        const data = Object.assign({}, form);
        data.idList = idList.value;
        batchEditPhotoMtd(data);
      }
      editFromUpload.value = true; // 默认上传按钮触发
    };
    const viewVisible = ref(false); // 查看弹窗
    /**
     * 查看照片弹窗显隐切换
     */
    const changeViewVisible = () => {
      viewVisible.value = false;
    };

    /**
     * 单个编辑照片信息
     * @param {data} 调用单个编辑图片所需所有参数
     */
    const singleEdit = (data, uploadTime, bool) => {
      singleEditPhotoMtd(data, uploadTime, bool);
    };
    const deleteVisible = ref(false); // 删除弹窗
    /**
     * 批量删除
     * @param {ids} 所选数据id集合
     */
    const handleBatchDelete = ids => {
      if (!ids.length) {
        message.warning("请先选择要操作的数据");
        return;
      }
      deleteTitleName.value = "批量删除";
      idList.value = ids;
      deleteVisible.value = true;
    };
    /**
     * 删除确认弹窗显隐切换
     */
    const changeDeleteVisible = () => {
      deleteVisible.value = false;
    };
    /**
     * 确认删除
     * @param {ids} 所选数据id集合
     */
    const handleDelete = ids => {
      isEmpty.value = true;
      deleteVisible.value = false;
      let data = {};
      if (viewDiaDelId.value) {
        data.idList = [viewDiaDelId.value];
      } else {
        data.idList = idList.value;
      }
      deletePhotoMtd(data);
      viewDiaDelId.value = null;
    };
    /**
     * 查看弹窗_单个删除
     */
    const viewDiaDelId = ref(null);
    const deleteTitleName = ref("删除");
    const singleDelete = (id, uploadTime) => {
      dateParam.value = uploadTime;
      viewDiaDelId.value = id;
      deleteTitleName.value = "删除";
      deleteVisible.value = true;
    };

    /**
     * 子组件触发更改monthDay的scrollTop或更新数据
     * @param {type} "refresh": 更新数据， "add": 显示全选，重新计算scrollTop， "cut": 隐藏全选，重新计算scrollTop
     */
    const changeMonthDay = (type, resData) => {
      if (type === "refresh") {
        Object.keys(resData).forEach(i => {
          const idx = monthDay.value.findIndex(it => it.uploadTime === i);
          if (idx >= 0) monthDay.value[idx].photoInfo = resData[i];
        });
      } else {
        monthDay.value.forEach((i, index) => {
          if (type === "add") i.scrollTop = i.scrollTop + index * 26;
          if (type === "cut") i.scrollTop = i.scrollTop - index * 26;
        });
      }
    };

    /**
     * 上传组件取消上传更新imgArr
     */
    const cancelName = arr => {
      imgArr.value = imgArr.value.filter(i => !arr.includes(i.name));
    };

    /** 方法相关 */
    /**
     * 上传图片或视频
     * @param {form} 编辑弹窗中名称和备注
     */
    const handleCreatePhoto = form => {
      const data = JSON.parse(JSON.stringify(imgArr.value));
      data.forEach(i => {
        i.isClass = isClass.value; // 是否班级相册 0:个人相册， 1:班级相册
        i.name = form.name || i.name && i.name.slice(0, i.name.lastIndexOf('.')); // 名称
        i.remark = form.remark; // 备注
        if (i.isClass) {
          // 班级相册入参
          i.deptId = deptInfo.value.id;
          i.deptName = deptInfo.value.name;
          i.institutionId = USER_INFO.content.institutionId;
        }
      });
      createPhoto(data).then(res => {
        if (res.data.code === 0) {
          message.success("上传成功");
          const data = getSearchDataMtd(0);
          const findData = monthDay.value.find(i => i.uploadTime === getCurYearMonthDayMtd());
          if (findData) {
            mainComRef.value.refreshPhotoList(data);
          } else {
            getPhotoList(); // 时间轴会变化则调初始获取相册接口
          }
          getPhotoCountMtd(1);
        }
      });
    };

    /**
     * 年月、月日时间轴处理
     * @param {resData} 相册列表接口返回数据
     */
    const dealTimeLineMtd = (resData, idList) => {
      // 班级相册_切换班级清空右侧数据
      yearMonth.value = [];
      monthDay.value = [];
      const {
        photoList,
        timeLine
      } = resData;
      const yAndM = [];
      timeLine.forEach(i => {
        i.yearMonth = i.dateStr.slice(0, 4) + "年" + Number(i.dateStr.slice(5, 7)) + "月";
        if (!yAndM.includes(i.yearMonth)) yAndM.push(i.yearMonth);
        monthDay.value.push({
          uploadTime: i.dateStr,
          dateStr: i.dateStr.slice(5).replace("-", "/"),
          jumpDate: i.yearMonth,
          // 点年月对应跳转日期id
          photoInfo: new Array(i.cnt).fill({}),
          cnt: i.cnt
        });
      });
      // 年月时间轴
      yAndM.forEach(i => {
        if (!yearMonth.value.find(it => it.yM === i.slice(0, 4))) {
          // 已有的年份则不重复添加到数组
          yearMonth.value.push({
            jumpDate: i,
            yM: i.slice(0, 4)
          });
        }
        yearMonth.value.push({
          jumpDate: i,
          yM: i.slice(5)
        });
      });
      // 月日时间轴
      const dateCheckBoxHeight = mainComRef.value.showCheckBox ? 64 : 38;
      monthDay.value.forEach((i, index) => {
        if (index > 0) {
          if (i.jumpDate === monthDay.value[index - 1].jumpDate) i.deleteJumpDate = true;
          i.scrollTop = monthDay.value[index - 1].scrollTop + dateCheckBoxHeight + Math.ceil(monthDay.value[index - 1].cnt / lineNum.value) * 212;
        } else {
          i.scrollTop = 0;
        }
      });
      monthDay.value = monthDay.value.map((i, index) => {
        const filterData = yearMonth.value.filter(it => it.jumpDate === i.jumpDate);
        const findIndex = yearMonth.value.findIndex(itm => itm.jumpDate === i.jumpDate);
        return {
          dateStr: i.dateStr,
          // leftIndex: filterData.length === 2 && i.deleteJumpDate ? findIndex + 1 : findIndex, // 用于滚动同步左侧年月时间轴
          leftIndex: filterData.length === 2 ? findIndex + 1 : findIndex,
          // 用于滚动同步左侧年月时间轴
          jumpDate: i.deleteJumpDate ? "" : i.jumpDate,
          photoInfo: i.photoInfo,
          cnt: i.cnt,
          scrollTop: i.scrollTop,
          uploadTime: i.uploadTime
        };
      });
      if (idList) {
        // 批量删除后已加载过数据赋值
        monthDay.value.forEach((i, index) => {
          const findData = cloneMonthDay.value.find(it => it.uploadTime === i.uploadTime);
          if (findData) {
            i.photoInfo = findData.photoInfo;
            i.cnt = findData.cnt;
            if (index > 0) {
              i.scrollTop = monthDay.value[index - 1].scrollTop + dateCheckBoxHeight + Math.ceil(monthDay.value[index - 1].cnt / lineNum.value) * 212;
            } else {
              i.scrollTop = 0;
            }
          }
        });
      }

      // 最近5日照片赋值
      if (resData.photoList) {
        Object.keys(resData.photoList).forEach(i => {
          const idx = monthDay.value.findIndex(it => it.uploadTime === i);
          monthDay.value[idx].photoInfo = resData.photoList[i];
        });
      }
      // console.log("--------364monthDay", monthDay.value);
    };

    /**
     * 调用批量修改照片接口
     */
    const batchEditPhotoMtd = data => {
      batchUpdatePhoto(data).then(res => {
        if (res.data.code === 0) {
          message.success("编辑成功");
          mainComRef.value.checkedNum = 0;
          monthDay.value.forEach(i => {
            // 本地修改数据
            i.photoInfo.forEach(it => {
              if (data.idList.includes(it.id)) {
                if (data.name) it.name = data.name;
                if (data.remark) it.remark = data.remark;
              }
            });
          });
          mainComRef.value.emptyCheckedMtd();
        }
      });
    };

    /** 
     * 调用单个修改照片接口
     */
    const singleEditPhotoMtd = (data, uploadTime, bool) => {
      updatePhoto(data).then(res => {
        if (res.data.code === 0) {
          message.success("编辑成功");
          refreshListMtd(uploadTime);
          if (bool) viewDialogRef.value.refreshCurInfoMtd(); // 更新refreshCurInfoMtd
        }
      });
    };

    /** 
     * 调用删除接口
     */
    const deletePhotoMtd = data => {
      delPhoto(data).then(res => {
        if (res.data.code === 0) {
          message.success("删除成功");
          mainComRef.value.emptyCheckedMtd();
          mainComRef.value.checkedNum = 0;
          getPhotoList(dateParam.value, data.idList); // 时间轴会变化则调初始获取相册接口
          getPhotoCountMtd(0);
          bus.emit("successDeletePhotoOrVideo");
        }
      });
    };

    /**
     * 批量删除，本地数据同步删除
     */
    const syncLocalDataMtd = idList => {
      if (Array.isArray(idList)) {
        // 批量删除，本地数据同步删除
        monthDay.value.forEach(i => {
          i.photoInfo.forEach((it, ix) => {
            if (idList.includes(it.id)) i.photoInfo.splice(ix, 1);
          });
        });
      }
    };

    /**
     * 刷新照片列表
     */
    const refreshListMtd = uploadTime => {
      var _mainComRef$value2;
      const data = getSearchDataMtd(1);
      (_mainComRef$value2 = mainComRef.value) === null || _mainComRef$value2 === void 0 ? void 0 : _mainComRef$value2.refreshPhotoList(data, uploadTime);
    };

    /**
     * 切换班级，清空右侧时间轴
     */
    const emptyTimeLineMtd = () => {
      yearMonth.value = [];
      monthDay.value = [];
    };

    /** 暴露方法 */
    __expose({
      emptyTimeLineMtd,
      getPhotoList,
      mainComRef
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(["top-div", {
          'weight-600': _unref(isClass)
        }])
      }, [_createElementVNode("div", null, _toDisplayString(_unref(isClass) ? _unref(deptInfo).name : "个人相册"), 1), !(__props.userType === 'student' && _unref(isClass) === 1 || isHideUploadBtn.value && _unref(isClass) === 0) ? (_openBlock(), _createBlock(uploadImg, {
        key: 0,
        imgArr: imgArr.value,
        "onUpdate:imgArr": _cache[0] || (_cache[0] = $event => imgArr.value = $event),
        sizeLimit: 200,
        isClass: _unref(isClass),
        userType: __props.userType
      }, null, 8, ["imgArr", "isClass", "userType"])) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_2, [_createVNode(mainCom, {
        ref_key: "mainComRef",
        ref: mainComRef,
        monthDay: monthDay.value,
        isEmpty: isEmpty.value,
        isClass: _unref(isClass),
        yearMonth: yearMonth.value,
        photoNum: photoNum.value,
        userType: __props.userType,
        deptInfo: _unref(deptInfo),
        onHandleBatchEdit: handleBatchEdit,
        onHandleBatchDelete: handleBatchDelete,
        onChangeMonthDay: changeMonthDay
      }, null, 8, ["monthDay", "isEmpty", "isClass", "yearMonth", "photoNum", "userType", "deptInfo"])]), showProgress.value ? (_openBlock(), _createBlock(uploadProgress, {
        key: 0,
        editVisible: editVisible.value,
        "onUpdate:editVisible": _cache[1] || (_cache[1] = $event => editVisible.value = $event),
        showProgress: "showProgress",
        isClass: _unref(isClass),
        class: "right-bottom",
        onBeginUpload: beginUpload,
        onCancelName: cancelName
      }, null, 8, ["editVisible", "isClass"])) : _createCommentVNode("", true), editVisible.value ? (_openBlock(), _createBlock(editDialog, {
        key: 1,
        "modal-visible": editVisible.value,
        onChangeVisible: changeEditVisible,
        onHandleUpload: handleUpload
      }, null, 8, ["modal-visible"])) : _createCommentVNode("", true), deleteVisible.value ? (_openBlock(), _createBlock(deleteDialog, {
        key: 2,
        "modal-visible": deleteVisible.value,
        titleName: deleteTitleName.value,
        class: "delete-dialog",
        onChangeVisible: changeDeleteVisible,
        onHandleUpload: handleDelete
      }, null, 8, ["modal-visible", "titleName"])) : _createCommentVNode("", true), viewVisible.value ? (_openBlock(), _createBlock(viewDialog, {
        key: 3,
        ref_key: "viewDialogRef",
        ref: viewDialogRef,
        userType: __props.userType,
        "modal-visible": viewVisible.value,
        viewInfo: viewInfo.value,
        isClass: _unref(isClass),
        deptInfo: _unref(deptInfo),
        institutionId: _unref(institutionId),
        class: "view-dialog",
        onChangeVisible: changeViewVisible,
        onSingleEdit: singleEdit,
        onChangeDelVisible: changeDeleteVisible,
        onHandleDel: handleDelete,
        onSingleDelete: singleDelete
      }, null, 8, ["userType", "modal-visible", "viewInfo", "isClass", "deptInfo", "institutionId"])) : _createCommentVNode("", true)]);
    };
  }
};