import "core-js/modules/es.array.push.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f4647ccc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "progress"
};
const _hoisted_2 = {
  class: "expand-pack"
};
const _hoisted_3 = {
  class: "left"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 0,
  class: "progress-text"
};
const _hoisted_6 = {
  key: 1,
  class: "progress-text orange-text"
};
const _hoisted_7 = {
  key: 0,
  class: "detail-div"
};
const _hoisted_8 = {
  key: 0,
  class: "item-photo"
};
const _hoisted_9 = {
  class: "photo-video"
};
const _hoisted_10 = ["src"];
const _hoisted_11 = ["src"];
const _hoisted_12 = ["src"];
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  class: "statu-div"
};
const _hoisted_15 = {
  class: "size-div"
};
const _hoisted_16 = ["onClick"];
import bus from "@/util/bus.js";
/** 组合式api */
import { ref, computed, toRefs } from "vue";
export default {
  __name: 'uploadProgress',
  props: {
    showProgress: {
      // 上传进度显隐
      type: Boolean,
      default: false
    },
    editVisible: {
      type: Boolean,
      default: false
    },
    isClass: {
      type: Number,
      default: null
    }
  },
  emits: ["update:editVisible", "beginUpload", "uploadOver", "cancelName"],
  setup(__props, {
    emit: __emit
  }) {
    /** 工具 */
    const props = __props;
    const {
      showProgress,
      isClass
    } = toRefs(props);
    const emit = __emit;

    // 上传文件列表
    const fileLists = ref([]);
    bus.off("beginUploadingPhotoesAndVideos");
    bus.on("beginUploadingPhotoesAndVideos", data => {
      fileLists.value = data;
      emit("beginUpload");
    });

    // 上传总进度
    const sumStatu = ref("上传进度");
    bus.on("afterUploadStatuChange", (data, res) => {
      fileLists.value = data;
      // 成功数量
      const filterSuccessData = data.filter(i => i.statu === "传输完成");
      successNum.value = filterSuccessData.length;

      // 全部处理完成
      const filterWaitData = data.filter(i => i.statu === "等待上传");
      if (filterWaitData.length === 0) {
        sumStatu.value = successNum.value === fileLists.value.length ? "上传完成" : "上传异常";
        if (sumStatu.value === '上传完成') {
          uploadOver();
        } else if (sumStatu.value === '上传异常') {
          emit("cancelName", cancelNameArr);
          cancelNameArr = [];
          isExpand.value = true;
        }
      }
    });

    /** 初始化相关 */
    // 是否加载中
    const isLoading = ref(true);
    // 是否收起
    const isExpand = ref(false);
    // 上传成功数量
    const successNum = ref(0);
    // 上传总数量
    const allSum = computed(() => {
      return fileLists.value.length;
    });

    /** 事件相关 */
    /**
     * 操作按钮 （删除/重新上传/取消上传）
     */
    let cancelNameArr = []; // 取消上传文件名字集合
    const clickBtn = (type, index) => {
      if (type === "取消上传") {
        cancelNameArr.push(fileLists.value[index].file.name);
        fileLists.value.splice(index, 1);
      }
      if (type === "重新上传") {
        bus.emit("reUploadFile", index);
      }
      if (type === "删除") {
        bus.emit("deleteUploadFile", fileLists.value[index]);
        fileLists.value.splice(index, 1);
      }
    };

    /**
     * 结束上传->显示编辑弹窗
     */
    const uploadOver = () => {
      const findData = fileLists.value.find(i => i.statu === "传输完成");
      emit("cancelName", cancelNameArr);
      cancelNameArr = [];
      fileLists.value = [];
      sumStatu.value = "上传进度";
      successNum.value = 0;
      isExpand.value = false;
      if (findData) emit("update:editVisible", true);
    };
    return (_ctx, _cache) => {
      const _component_t_icon = _resolveComponent("t-icon");
      const _component_t_button = _resolveComponent("t-button");
      return fileLists.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: sumStatu.value === '上传进度' ? require('@/assets/uploading.svg') : sumStatu.value === '上传完成' ? require('@/assets/upload-success.svg') : require('@/assets/upload-failed.svg'),
        alt: "加载异常",
        class: _normalizeClass(["loading-icon", {
          'loading-animate': sumStatu.value === '上传进度'
        }])
      }, null, 10, _hoisted_4), sumStatu.value !== '上传异常' ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(sumStatu.value) + _toDisplayString(successNum.value) + "/" + _toDisplayString(allSum.value), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(sumStatu.value) + "(" + _toDisplayString(allSum.value - successNum.value) + ")", 1))]), sumStatu.value !== '上传异常' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [!isExpand.value ? (_openBlock(), _createBlock(_component_t_icon, {
        key: 0,
        name: "chevron-up",
        onClick: _cache[0] || (_cache[0] = $event => isExpand.value = !isExpand.value)
      })) : (_openBlock(), _createBlock(_component_t_icon, {
        key: 1,
        name: "chevron-down",
        onClick: _cache[1] || (_cache[1] = $event => isExpand.value = !isExpand.value)
      }))], 64)) : (_openBlock(), _createBlock(_component_t_button, {
        key: 1,
        onClick: uploadOver
      }, {
        default: _withCtx(() => [_createTextVNode("确定")]),
        _: 1
      }))]), isExpand.value ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fileLists.value, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [!(sumStatu.value === '上传异常' && item.statu === '传输完成') ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [item.statu === '等待上传' ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require('@/assets/loading.svg'),
          alt: "",
          class: "loading-animate"
        }, null, 8, _hoisted_10)) : _createCommentVNode("", true), item.statu === '传输失败' ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: require('@/assets/failed-img.svg'),
          alt: ""
        }, null, 8, _hoisted_11)) : _createCommentVNode("", true), item.url && item.file.type.split('/').includes('image') ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: item.url,
          alt: "",
          class: "img-sty"
        }, null, 8, _hoisted_12)) : _createCommentVNode("", true), item.url && !item.file.type.split('/').includes('image') ? (_openBlock(), _createElementBlock("video", {
          key: 3,
          src: item.url,
          class: "img-sty"
        }, null, 8, _hoisted_13)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", {
          class: _normalizeClass({
            'orange-text': item.statu === '传输失败'
          })
        }, _toDisplayString(item.statu), 3), _createElementVNode("div", _hoisted_15, _toDisplayString(item.showSize), 1)]), _createElementVNode("div", {
          class: _normalizeClass([{
            'orange-btn': item.statu === '传输失败'
          }, "operate-btn"]),
          onClick: $event => clickBtn(item.btnTxt, index)
        }, _toDisplayString(item.btnTxt), 11, _hoisted_16)])) : _createCommentVNode("", true)], 64);
      }), 128))])) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
    };
  }
};